// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-download-ebook-js": () => import("./../../../src/pages/download-ebook.js" /* webpackChunkName: "component---src-pages-download-ebook-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-seekers-jobs-js": () => import("./../../../src/pages/job-seekers/jobs.js" /* webpackChunkName: "component---src-pages-job-seekers-jobs-js" */),
  "component---src-pages-mechanics-register-with-us-candidate-js": () => import("./../../../src/pages/mechanics/register-with-us-candidate.js" /* webpackChunkName: "component---src-pages-mechanics-register-with-us-candidate-js" */),
  "component---src-pages-workshops-blog-js": () => import("./../../../src/pages/workshops/blog.js" /* webpackChunkName: "component---src-pages-workshops-blog-js" */),
  "component---src-pages-workshops-contact-us-js": () => import("./../../../src/pages/workshops/contact-us.js" /* webpackChunkName: "component---src-pages-workshops-contact-us-js" */),
  "component---src-pages-workshops-news-js": () => import("./../../../src/pages/workshops/news.js" /* webpackChunkName: "component---src-pages-workshops-news-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

